import { useSearchParams } from '@remix-run/react';
import { useEffect } from 'react';

export type StoredFormValuesLookup = Record<string, string[] | null>;
const DEFAULT_PROGRESS_KEY = 'form-progress';
const FORM_PROGRESS_USER_KEY = 'form-progress-user';
export const DEFAULT_CLEAR_PROGRESS_PARAM_KEY = '_action';
export const DEFAULT_CLEAR_PROGRESS_PARAM_VALUE = 'evaluationSucceeded';

export const saveFormDataToLocalStorage = (
  form: HTMLFormElement,
  userId: string,
  formProgressKey = DEFAULT_PROGRESS_KEY
) => {
  const formData = new FormData(form);
  // @ts-expect-error TS says formData is not acceptable, but all my tests show it does
  const searchParams = new URLSearchParams(formData);
  const serializedForm = searchParams.toString();
  localStorage.setItem(FORM_PROGRESS_USER_KEY, userId);
  localStorage.setItem(formProgressKey, serializedForm);
};

export const clearFormDataFromLocalStorage = (
  formProgressKey = DEFAULT_PROGRESS_KEY
) => {
  try {
    console.log(
      `Clearing form data for ${DEFAULT_PROGRESS_KEY} from local storage`
    );
    localStorage.removeItem(formProgressKey);
    localStorage.removeItem(FORM_PROGRESS_USER_KEY);
  } catch (error) {
    console.error(
      `Error clearing form data for ${DEFAULT_PROGRESS_KEY} from local storage`,
      error
    );
  }
};

export const loadFormDataFromLocalStorage = (
  userId: string,
  formProgressKey = DEFAULT_PROGRESS_KEY
): StoredFormValuesLookup | null => {
  const currentUserId = localStorage.getItem(FORM_PROGRESS_USER_KEY);
  if (currentUserId !== userId) {
    clearFormDataFromLocalStorage(formProgressKey);
  }
  const cachedString = localStorage.getItem(formProgressKey);
  return cachedString ? convertQueryStringToRecord(cachedString) : null;
};

export const convertQueryStringToRecord = (queryString: string) => {
  const params = new URLSearchParams(queryString);
  const record: StoredFormValuesLookup = {};

  params.forEach((value, key) => {
    record[key] = value === '' ? null : params.getAll(key);
  });

  return record;
};

/**
 * Observes the URL for a query parameter that indicates a form submission was successful.
 * Deletes the query parameter from the URL and clears the form data from LocalStorage.
 */
export const useClearFormData = (
  paramValue = DEFAULT_CLEAR_PROGRESS_PARAM_VALUE,
  paramKey = DEFAULT_CLEAR_PROGRESS_PARAM_KEY
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // We use useEffect here because this is a clientside-only action (clearing LocalStorage). Otherwise, we get this message:
  // "You should call navigate() in a React.useEffect(), not when your component is first rendered."
  useEffect(() => {
    if (searchParams.get(paramKey) === paramValue) {
      clearFormDataFromLocalStorage();
      searchParams.delete(paramKey);
      // we don't want to keep the URL with _action in the history
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, paramKey, paramValue]);
};
